'use strict'

import { RestUrl } from './Rest'
import moment from 'moment'

function numberNullCheck(obj) {
  if (!obj || isNaN(obj) | (obj == undefined)) {
    obj = 0
  }
  return obj
}

const Common = {
  momentRe(date) {
    return moment(date)
  },
  moment(date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  },
  momentDay(date) {
    return moment(date).format('YYYY-MM-DD')
  },
  momentKo(date) {
    return moment(date).format('YYYY년 MM월 DD일 HH시')
  },

  toMoneyFormat(str, inx, fix, com) {
    if (typeof fix === 'undefined') {
      fix = false
    }

    if (typeof com === 'undefined') {
      com = true
    }

    str = numberNullCheck(str)

    let minus = ''
    let major = ''
    let minor = ''
    let target = ''
    let dot = ''

    str = Number(str).toFixed(12)

    if (str.charAt(0) == '-') {
      minus = '-'
      str = str.substring(1)
    }

    if (str.indexOf('.') > 0) {
      major = str.substring(0, str.indexOf('.'))
      minor = str.substring(str.indexOf('.') + 1, str.length)
      dot = '.'
    } else {
      major = str
    }
    if (com) {
      if (major.length > 3) {
        for (var i = major.length; i > 0; i -= 3) {
          if (i - 3 > 0) {
            target = major.substring(i - 3, i) + target
            target = ',' + target
          } else {
            target = major.substring(0, i) + target
          }
        }
      } else {
        target = major
      }
    } else {
      target = major
    }

    // 뒤에 0을 제거 한다.
    if (minor.length > inx) {
      minor = minor.substring(0, inx)
    }
    if (!fix) {
      minor = minor.replace(/(0+$)/, '')
    }
    if (minor.length == 0) {
      dot = ''
    } else {
      dot = '.'
    }

    return minus + target + dot + minor
  },

  comma(data) {
    return Common.toMoneyFormat(data, 8)
  },
  comma2(data) {
    return Common.toMoneyFormat(data, 2)
  },
  commaUsd(data) {
    return Common.toMoneyFormat(data, 4)
  },
  statusList:{
    'R':'요청상태',
    'C':'완료',
    'P':'펜딩',
    'S':'진행중',
    'F':'실패'
  },
  renderStatus(status) {
    var text = '';
    if(Common.statusList[status] != undefined){
      text = Common.statusList[status];
    }
    return text
  },
  typeList:{
    'II':'내부 입금',
    'EI':'외부 입금',
    'IO':'내부 출금',
    'EO':'외부 출금',
    'TF':'수수료',
    'AR':'관리자 입금',
    'AB':'관리자 출금',
    'FR':'추천 친구 리워드',
    'PI':'스테이킹 구매',
    'SO':'교환 출금',
    'SI':'교환 입금',
    'LM':'Floor Bonus',
    'LR':'원급 분할 지급',
    'FD':'Founder bonus',
    'LD':'이자 지급',
    'LZ':'이자 지급(마스터)',
    'LW':'지급',
    'LC':'라인',
    'LG':'Level Incentive'
  },
  renderType(type) {
    var text = '';
    if(Common.typeList[type] != undefined){
      text = Common.typeList[type];
    }
    return text
  },
  renderLangType(status) {
    var setVal = Common.langOption.find(e=> e.value == status);
    if(setVal){
      return setVal.value
    }
    return '-';
  },

  langOption : [
    { value: "en", label: "영어" },
    { value: "zh", label: "중국어" },
    { value: "ja", label: "일본어" },
    { value: "vi", label: "베트남어" },
    { value: "th", label: "태국어" },
  ],

  dateFormat(date) {
    if (date) {
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      return `${year}-${month}-${day}`
    } else {
      return null
    }
  },
  dateTimeFormat(date) {
    if (date) {
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      const Hours = date.getHours()
      const Minutes = date.getMinutes();
      const Seconds = date.getSeconds();

      return `${year}-${month}-${day} ${Hours}:${Minutes}:${Seconds}`
    } else {
      return null
    }
  },

  serialize(obj) {
    let str = []
    for (let p in obj)
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
      }
    return str.join('&')
  },

  windowScrollTop() {
    window.scrollTo(0, 0)
  },

  ruleSelect: '필수 선택 입니다.',
  ruleText: '필수 입력 입니다.',

  setClipboard(str){
   
    window.navigator.clipboard.writeText(str).then((e) => {
       alert("복사 완료");
    });
  }
}

export {Common}
