export const logoNegative = [
'109 40',
`
<g id="app" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="00_시작_01_로그인" transform="translate(-118.000000, -334.000000)" fill-rule="nonzero">
            <g id="Spark" transform="translate(118.440000, 334.800000)">
                <path d="M8.964,11.628 C7.2,11.628 6.12,10.44 6.12,8.928 C6.12,6.372 7.992,5.976 10.332,5.976 L20.664,5.976 L20.664,0 L7.992,0 C3.168,0 0,4.104 0,8.64 C0,15.012 3.996,17.604 10.116,17.604 L13.752,17.604 C16.02,17.604 17.784,18.972 17.784,21.312 C17.784,23.796 15.768,24.948 13.32,24.948 L0.972,24.948 L0.972,30.636 L14.94,30.636 C19.944,30.636 23.364,26.604 23.364,21.312 C23.364,15.696 19.584,11.628 14.472,11.628 L8.964,11.628 Z" id="Path" fill="#ffffff"></path>
                <path d="M26.352,7.74 L26.352,38.448 L31.536,38.448 L31.536,30.024 C32.616,30.42 33.84,30.636 35.172,30.636 L36.612,30.636 C41.832,30.636 45.072,27.324 45.072,22.248 L45.072,16.092 C45.072,11.016 41.832,7.74 36.612,7.74 L26.352,7.74 Z M36.612,12.888 C38.52,12.888 39.924,14.292 39.924,16.236 L39.924,22.104 C39.924,24.048 38.52,25.452 36.612,25.452 L34.776,25.452 C32.868,25.452 31.536,24.048 31.536,22.104 L31.536,12.888 L36.612,12.888 Z" id="Shape" fill="#ffffff"></path>
                <path d="M52.884,14.976 C53.28,13.536 54.072,12.852 56.52,12.852 L57.636,12.852 C60.84,12.852 61.272,14.472 61.272,16.632 L61.272,17.1 L53.568,17.1 C49.716,17.1 47.484,19.944 47.484,23.724 C47.484,27.468 49.644,30.636 53.676,30.636 L66.456,30.636 L66.456,15.552 C66.456,11.664 63.792,7.74 58.428,7.74 L55.728,7.74 C50.508,7.74 47.952,11.16 47.7,14.976 L52.884,14.976 Z M52.308,23.724 C52.308,22.176 53.1,21.42 53.856,21.42 L61.272,21.42 L61.272,25.992 L53.856,25.992 C53.1,25.992 52.308,25.236 52.308,23.724 Z" id="Shape" fill="#ffffff"></path>
                <path d="M75.672,12.42 L75.672,7.56 L70.092,7.56 L70.092,30.636 L75.672,30.636 L75.672,21.096 C75.672,17.64 80.928,13.752 85.284,13.752 L85.896,13.752 L85.896,8.172 L85.284,8.172 C81.468,8.172 77.4,9.612 75.672,12.42 Z" id="Path" fill="#ffffff"></path>
                <polygon id="Path" fill="#ffffff" points="96.984 18.324 108 7.74 101.772 7.74 94.464 14.976 94.464 0 89.172 0 89.172 30.636 94.464 30.636 94.464 21.924 102.204 30.636 108.144 30.636"></polygon>
            </g>
        </g>
    </g>
`,
]
