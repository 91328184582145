<template>
  <CHeader position="sticky">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand
        class="me-auto d-flex ms-2"
        to="/"
        v-if="sidebarVisible != true"
      >
        <CIcon :icon="logo" height="20" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav>
        <span v-if="adminType" class=""
        style="    display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 500;">{{ adminType}}</span>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import { computed } from 'vue'
import { useStore,mapGetters} from 'vuex'
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { logo } from '@/assets/brand/logo'
import Session from '@/modules/Session'
export default {
    components: { AppBreadcrumb,
    AppHeaderDropdownAccnt,},
    data() {
        return {
            root: this,
            logo:logo,
            sidebarVisible: computed(() => useStore().state.sidebarVisible),
            adminType:Session.getAdminType()
        } 
    },
    computed: {
        isLoading() {
            return this.$store.state.isLoading
        },
        ...mapGetters([
        'getEnv',
        ])
    },
    async created() {},
    methods: {
        setIsLoading(bool) {
            this.$store.commit('setIsLoading', bool)
        },
        setEnv(value) {
            this.$store.commit('setEnv', value)
        },
    },
}

</script>
